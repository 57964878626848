<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const { depositInfoData } = useRabbitDepositStreak();

const handleOpenCash = () => {
	if (depositInfoData.value?.canWheel) {
		open("LazyOModalDepositStreak");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<div class="wrapper">
		<NuxtImg
			class="image"
			src="/nuxt-img/deposit-streak/banner.webp"
			alt="banner-streak"
			width="992"
			height="442"
			loading="lazy"
		/>
		<div class="content">
			<AText>
				{{ t("depositStreak.banner.title", { maxDep: depositInfoData?.maxNumberOfDeposit }) }}
			</AText>
			<AText class="subtitle" :size="24" :modifiers="['semibold']">
				{{ t("depositStreak.banner.subtitle", { maxPercentage: depositInfoData?.maxPercentage }) }}
			</AText>
			<AAnimationCustomByScroll animationClass="blink" transitionDelay="3s" class="btn-wrapper">
				<AButton variant="primary" class="btn" size="lg" @click="handleOpenCash">
					<AText :size="16" :modifiers="['medium']">
						{{
							depositInfoData.canWheel ? t("depositStreak.mainModal.btn.claim") : t("depositStreak.banner.btn.store")
						}}
					</AText>
				</AButton>
			</AAnimationCustomByScroll>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	width: 100%;
	height: 50%;
	border-radius: 24px;
	border: 2px solid var(--cordoba);
	display: flex;
	padding: 24px;
	position: relative;
	overflow: hidden;
}

.content {
	max-width: 320px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	position: relative;
	z-index: 1;
}

.subtitle {
	max-width: 226px;
}

.image {
	height: 100%;
	width: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
}

.btn-wrapper {
	margin-top: auto;
}

.btn {
	width: 165px;
}
</style>
